import { various } from 'config';
import useData from 'hooks/useData';
import Layout from 'components/general/Layout';
import { isCurrent, formatDate } from 'utils';
import NoData from 'components/general/NoData';

export default function ChairmanReader(): JSX.Element {
    const { data: allData } = useData(various, 'Presidencia - Lectura');
    const rawData = allData as any;

    const data = rawData?.map((item: any) => ({
        fecha: item.c[0]?.f ||'',
        presidente: item.c[1]?.v ||'',
        lector: item.c[2]?.v ||'',
    })) || [];

    const currentData = data.filter((item: any) => isCurrent(item.fecha));

    return (
        <Layout title="Presidente / Lector Atalaya">
            {!currentData.length && (
                <NoData />
            )}
            {currentData.map((item: any, index: number) => (
                <div className="block-container" key={index}>
                    <div className="date">{formatDate(item.fecha)}</div>
                    <div className="president"><b>Presidente:</b> {item.presidente}</div>
                    <div className="reader"><b>Lector:</b> {item.lector}</div>
                </div>
            ))}
        </Layout>
    );
}
