export const congr_name = 'Fleming';

export const service = '1ck25duI7b5hNk-D7yPOXusXjK20-8E2-dJHx7QJpeK8';
export const groups = '1GFlh5Ypcm74SSQ-zOSC4dkz1yHtgIKd-xNoWCT7AwvA';
export const speaches = '1k6f-JVrDTkdWr7tgc-M43Vzpeltw_Yae1caGT3n5tzg';
export const various = '1W0B4hBdh-cK2dut29AYZ5Uc2iU65x5bPBGNwNtXT5L0';
export const cleaning = '1NmKUaNXbBj9i2OCwn2R9k7Fn17A7aewW3zQh8vDPYT4';

export const menu = [
    {
        title: 'Predicación',
        submenu: [
            { title: 'De casa en casa', url: '/' },
            { title: 'Por Zoom', url: '/zoom' },
            { title: 'Carritos', url: '/carritos'},
            { title: '', },
            { title: 'Direcciones "No pasar"', url: '/no-pasar'},
            { title: 'Grupos de servicio', url: '/grupos'},
        ]
    },
    {
        title: 'Reuniones',
        submenu: [
            { title: 'Reunión Vida y Ministerio', url: 'https://vmc.juanruben.com/?cid=2',},
            { title: '', },
            { title: 'Conferencias', url: '/conferencias'},
            { title: 'Conferencias salientes', url: '/conferencias-salientes'},
            { title: 'Presidencia / Lectura Atalaya', url: '/presidentes-lectores'},
        ]
    },
    {
        title: 'Otros privilegios',
        submenu: [
            { title: 'Aseo del Salón del Reino', url: '/aseo'},
            { title: '', },{ title: 'Varios', url: '/varios'},
        ]
    },
];
