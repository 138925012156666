import Layout from 'components/general/Layout';
import useData from 'hooks/useData';
import { removeDuplicates, groupBy, isCurrent, formatDate } from 'utils';

import Territory from 'components/pages/Territory';

import { service } from 'config';
import NoData from 'components/general/NoData';

export default function Preaching(): JSX.Element{
    const { data: allData } = useData(service, 'Casa en casa');
    const rawData = allData as any;

    const rows: any[] = rawData?.map((item: any) => ({
        fecha: item.c[0]?.v || '',
        hora: item.c[1]?.v || '',
        salida: item.c[2]?.v || '',
        grupos: item.c[3]?.v || '',
        territorio: item.c[4]?.v || '',
        encargado: item.c[5]?.v || '',
        telefono: item.c[6]?.v || '',
    })) || [];

    const grouped = groupBy(rows, 'fecha');

    const data = [];


    const { data: allDataNoVisit } = useData(service, 'No Pasar');
    const rawDataNoVisit = allDataNoVisit as any;

    const rowsNoVisit: any[] = rawDataNoVisit?.map((item: any) => ({
        territorio: item.c[0]?.v ||'',
        calle: item.c[1]?.v ||'',
        numero: item.c[2]?.v ||'',
    })) || [];

    const groupedNoVisit = groupBy(rowsNoVisit, 'territorio');

    const dataNoVisit: any[] = [];

    for (const ii in groupedNoVisit) {
        dataNoVisit.push({
            territorio: ii,
            direcciones: groupedNoVisit[ii],
        });
    }

    for (const ii in grouped) {
        data.push({
            fecha: ii,
            servicio: removeDuplicates(grouped[ii], 'salida'),
        });
    }

    const currentData = data.filter((item: any) => isCurrent(item.fecha));

    return (
        <Layout title="Predicación de casa en casa">
            {!currentData.length && (
                <NoData />
            )}
            {currentData.map((item, index) => (
                <div key={index}>
                    <div className="block-container" key={index}>
                        <div className="service-container">
                            <div className="date">{formatDate(item.fecha)}</div>
                            <ul>
                                {item.servicio.map((subItem, index2) => (
                                    <li key={index2} className="mx-2">
                                        <span>{subItem.hora}</span> <span className="salida">{subItem.salida}</span>
                                        {!!subItem.grupos && (
                                            <div className="grupos">Grupos: {subItem.grupos}</div>
                                        )}
                                        <div>
                                            {(subItem.territorio !== 'Calles' && subItem.territorio !== 'Telefónico') ?
                                                subItem.territorio.toString().split(' ').map((terr: any, index: number) => (
                                                    <Territory key={index} id={terr} data={dataNoVisit?.find((item: any) => item.territorio === terr)?.direcciones} />
                                                ))
                                                :
                                                <span className="territorio">({subItem.territorio})</span>
                                            }
                                        </div>
                                        {subItem.encargado && (
                                            <div className="conductor">
                                                <a className="telefono" href={'tel:+56' + subItem.telefono}>
                                                    <i className="fas fa-phone"></i>
                                                    <span className="px-3">{subItem.encargado}</span>
                                                </a>
                                            </div>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            ))}
        </Layout>
    );
}

