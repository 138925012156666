import { service } from 'config';
import useData from 'hooks/useData';
import { groupBy, isCurrent, formatDate } from 'utils';
import Layout from 'components/general/Layout';
import NoData from 'components/general/NoData';

export default function PreachingZoom(): JSX.Element {
    const { data: allData } = useData(service, 'Zoom');
    const rawData = allData as any;

    const rows: any[] = rawData?.map((item: any) => ({
        fecha: item.c[0]?.v || '',
        hora: item.c[1]?.v || '',
        nota: item.c[2]?.v || '',
        grupos: item.c[3]?.v || '',
        territorio: item.c[4]?.v || '',
        encargado: item.c[5]?.v || '',
        telefono: item.c[6]?.v || '',
        link: item.c[7]?.v || '',
        id: item.c[8]?.v || '',
        password: item.c[9]?.v || '',
    })) || [];

    const grouped = groupBy(rows, 'fecha');

    const data = [];

    for (const ii in grouped) {
        data.push({
            fecha: ii,
            servicio: grouped[ii], // removeDuplicates(grouped[ii], 'grupos'),
        });
    }

    const currentData = data.filter((item: any) => isCurrent(item.fecha));

    return (
        <Layout title="Predicación por Zoom">
            {!currentData.length && (
                <NoData />
            )}
            {currentData.map((item: any) => (
                <div key={item.fecha}>
                    <div className="block-container" key={item.fecha}>
                        <div className="service-container">
                            <div className="date">{formatDate(item.fecha)}</div>
                            <ul>
                                {item.servicio.map((ss: any, index: number) => (
                                    <li key={index} className="mx-2">
                                        <span className="hora">{ss.hora}</span>
                                        {ss.link && <span className="salida"><a href={ss.link}>Link de zoom</a></span>}
                                        {ss.nota && <div className="nota">{ss.nota}</div>}
                                        {ss.id && <div className="small id-meet"><i>ID de reunión:</i> {ss.id}</div>}
                                        {ss.password && <div className="small password-meet"><i>Contraseña:</i> {ss.password}</div>}
                                        {ss.grupos && <div className="grupos">Grupos: {ss.grupos}</div>}
                                        {ss.territorio && (<span className="territorio">Territorios: {ss.territorio}</span>)}
                                        <div className="conductor"><a className="telefono" href={'tel:+56' + ss.telefono}><i className="fas fa-phone"></i>{' '}{ss.encargado}</a></div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            ))}
        </Layout>
    );
}
