import { cleaning } from 'config';
import useData from 'hooks/useData';
import Layout from 'components/general/Layout';
import { formatDate, isCurrent } from 'utils';
import NoData from 'components/general/NoData';

export default function Cleaning(): JSX.Element {
    const { data: allData } = useData(cleaning);
    const rawData = allData as any;

    const data = rawData?.map((item: any) => ({
        fecha: item.c[0]?.f ||'',
        grupo: item.c[1]?.v ||'',
    })) || [];

    const currentData = data.filter((item: any) => isCurrent(item.fecha));

    return (
        <Layout title="Aseo del Salón del Reino">
            {!currentData.length && (
                <NoData />
            )}
            {currentData.map((item: any, index: number) => (
                <div className="block-container" key={index}>
                    <div className="date-cleaning">{formatDate(item.fecha)}</div>
                    <div className="group">{item.grupo}</div>
                </div>
            ))}
        </Layout>
    );
}
