import { speaches } from 'config';
import useData from 'hooks/useData';
import Layout from 'components/general/Layout';

export default function SpeachesOut(): JSX.Element {
    const { data: allData } = useData(speaches, 'Publico saliente');
    const rawData = allData as any;

    const data =
    rawData?.map((item: any) => ({
        fecha: item.c[0]?.f || '',
        nro: item.c[1]?.v || '',
        tema: item.c[2]?.v || '',
        discursante: item.c[3]?.v || '',
        congregacion: item.c[4]?.v || '',
        hora: item.c[5]?.f || '',
    })) || [];

    return (
        <Layout title="Conferencias salientes">
            {data.map((item: any, index: number) => (
                <div className="block-container" key={index}>
                    <div className="date">{item.fecha}</div>
                    <div className="speach-theme">{item.tema}</div>
                    <div className="speacher">{item.discursante}</div>
                    <div className="congregation"> - Congr. {item.congregacion}</div>
                    <div className="congregation"> - Hora: {item.hora}</div>
                </div>
            ))}
        </Layout>
    );
}
