import * as React from 'react';
import { Collapse, CardBody, Card, Modal, ModalHeader, ModalBody } from 'reactstrap';
import 'react-accessible-accordion/dist/fancy-example.css';
import t1 from 'assets/T1.png';
import t2 from 'assets/T2.png';
import t3 from 'assets/T3.png';
import t4 from 'assets/T4.png';
import t5 from 'assets/T5.png';
import t6 from 'assets/T6.png';
import t7 from 'assets/T7.png';
import t8 from 'assets/T8.png';
import t9 from 'assets/T9.png';
import t10 from 'assets/T10.png';
import t11 from 'assets/T11.png';
import t12 from 'assets/T12.png';
import t13 from 'assets/T13.png';
import t14 from 'assets/T14.png';
import t15 from 'assets/T15.png';
import t16 from 'assets/T16.png';
import t17 from 'assets/T17.png';
import t18 from 'assets/T18.png';
import t19 from 'assets/T19.png';
import t20 from 'assets/T20.png';
import t21 from 'assets/T21.png';
import t22 from 'assets/T22.png';
import t23 from 'assets/T23.png';
import t24 from 'assets/T24.png';
import t25 from 'assets/T25.png';
import t26 from 'assets/T26.png';
import t27 from 'assets/T27.png';
import t28 from 'assets/T28.png';
import t29 from 'assets/T29.png';
import t30 from 'assets/T30.png';

interface Props {
    id: string
    data: any[]
}

export default function Territory({ id, data }: Props): JSX.Element {
    const [collapse, setCollapse] = React.useState(false);
    const [modal, setModal] = React.useState(false);

    const toggle = () => setModal(!modal);

    const toggleCollapse = () => setCollapse(!collapse);

    const getImage = (territory: number) => {
        switch (territory){
        case 1:
            return t1;
        case 2:
            return t2;
        case 3:
            return t3;
        case 4:
            return t4;
        case 5:
            return t5;
        case 6:
            return t6;
        case 7:
            return t7;
        case 8:
            return t8;
        case 9:
            return t9;
        case 10:
            return t10;
        case 11:
            return t11;
        case 12:
            return t12;
        case 13:
            return t13;
        case 14:
            return t14;
        case 15:
            return t15;
        case 16:
            return t16;
        case 17:
            return t17;
        case 18:
            return t18;
        case 19:
            return t19;
        case 20:
            return t20;
        case 21:
            return t21;
        case 22:
            return t22;
        case 23:
            return t23;
        case 24:
            return t24;
        case 25:
            return t25;
        case 26:
            return t26;
        case 27:
            return t27;
        case 28:
            return t28;
        case 29:
            return t29;
        case 30:
            return t30;
        default:
            return '';
        }
    };

    return (
        <>
            <button className="territory-badge" color="success" onClick={toggle}>
                {id}
            </button>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}><i className="fas fa-map-marked-alt"></i> Territorio {id}</ModalHeader>
                <ModalBody>
                    <img className="territory-image" src={getImage(parseInt(id))}/>

                    {data && (
                        <>
                            <div className="territory-button-np" onClick={toggleCollapse}>
                                No pasar &nbsp;&nbsp;&nbsp;<i className="fas fa-chevron-down"></i>
                            </div>
                            <Collapse isOpen={collapse}>
                                <Card>
                                    <CardBody>
                                        {data.map((item: any, index: number) => (
                                            parseInt(item.territorio) === parseInt(id) ?
                                                <div key={index}>• {item.calle} {item.numero}</div>
                                                :
                                                null
                                        ))}
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
}
