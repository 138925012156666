import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';

export default function useData(spreadsheetId: string, sheet: string | undefined = undefined): UseQueryResult {
    const loadData = async () => {
        const endpoint = `https://docs.google.com/spreadsheets/d/${spreadsheetId}/gviz/tq?tqx=out:json${sheet ? `&sheet=${sheet}` : ''}`;
        const response = await axios.get(endpoint);
        const { table } = JSON.parse(response.data.substr(47).slice(0, -2));

        return table.rows;
    };

    return useQuery([spreadsheetId, sheet], loadData);
}
