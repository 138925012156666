import useData from 'hooks/useData';
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';
import { groups } from 'config';
import { groupBy } from 'utils';
import Layout from 'components/general/Layout';

export default function Groups(): JSX.Element {
    const { data: groupsData } = useData(groups);
    const rawData = groupsData as any;

    const rows: any[] = rawData?.map((item: any) => ({
        grupo: item.c[0]?.v ||'',
        nombre: item.c[1]?.v ||'',
        apellido: item.c[2]?.v ||'',
        precursor: item.c[3]?.v ||'',
        encargado: item.c[4]?.v ||'',
        auxiliar: item.c[5]?.v ||'',
    })) || [];

    const grouped = groupBy(rows, 'grupo');

    const data = [];

    for (const i in grouped) {
        data.push({
            grupo: i,
            miembros: grouped[i],
        });
    }

    return (
        <Layout title="Grupos de servicio">
            <Accordion atomic={true}>
                {data.map(item => (
                    <AccordionItem key={item.grupo} title={'Grupo ' + item.grupo}>
                        <div className="group-container">
                            {item.miembros.map((subitem: any) => (
                                <div key={`${subitem.nombre}${subitem.apellido}`} className={'group-item ' + (subitem.precursor ? ' precursor' : '') + (subitem.encargado ? ' encargado' : '') + (subitem.auxiliar ? ' auxiliar' : '')}>
                                    {subitem.nombre} {subitem.apellido}
                                </div>
                            ))
                            }
                        </div>
                    </AccordionItem>
                ))}
            </Accordion>
        </Layout>
    );
}
