import * as React from 'react';
import Loader from 'components/general/Loader';
import { useIsFetching } from 'react-query';

interface Props {
    title: string
    children: React.ReactNode
}

export default function Layout ({ title, children }: Props): JSX.Element {
    const isFetching = useIsFetching();

    return (
        <div className="main-content">
            <h1>{title}</h1>
            <div className="main-container">
                {isFetching ? (
                    <Loader />
                ):(
                    <div className="main-frame">
                        {children}
                    </div>
                )}
            </div>
        </div>
    );
}
