import { Accordion, AccordionItem } from 'react-light-accordion';
import { service } from 'config';
import useData from 'hooks/useData';
import { groupBy } from 'utils';
import Layout from 'components/general/Layout';

export default function NoVisit(): JSX.Element {
    const { data: allData } = useData(service, 'No Pasar');
    const rawData = allData as any;

    const rows: any[] = rawData?.map((item: any) => ({
        territorio: item.c[0]?.v ||'',
        calle: item.c[1]?.v ||'',
        numero: item.c[2]?.v ||'',
    })) || [];

    const grouped = groupBy(rows, 'territorio');

    const data = [];

    for (const ii in grouped) {
        data.push({
            territorio: ii,
            direcciones: grouped[ii],
        });
    }

    return (
        <Layout title="No pasar">
            <Accordion atomic={true}>
                {data.map((item: any) => (
                    <AccordionItem key={item.territorio} title={'Territorio ' + item.territorio}>
                        <div className="group-container">
                            {item.direcciones?.map((subitem: any) => (
                                <div key={`${subitem.calle} ${subitem.numero}`} className="no-visit">
                                    {`${subitem.calle} ${subitem.numero}`}
                                </div>
                            ))}
                        </div>
                    </AccordionItem>
                ))}
            </Accordion>
        </Layout>
    );
}
