import { format } from 'date-fns';
import es from 'date-fns/locale/es';

export const getToken = (): string => {
    return localStorage.getItem('SESSION') || '';
};

export const setToken = (name: string, lastname: string): void => {
    localStorage.setItem('SESSION', JSON.stringify({ name, lastname }));
};

export const groupBy = function(xs: any[], key: string): any {
    return xs.reduce(function(rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const removeDuplicates = (originalArray: any[], prop: string): any[] => {
    const newArray = [];
    const lookupObject: any = {};

    for (const i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (const i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
    return newArray;
};

export const isCurrent = (date: string): boolean => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate()-1);

    return (new Date(date)) > yesterday;
};

export const formatDate = (date: string): string => {
    return format(new Date(date.replaceAll('-', '/')), 'EEEE dd \'de\' MMMM', { locale: es });
};
