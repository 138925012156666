import * as React from 'react';
import { Form, FormGroup, Col, Label, Input } from 'reactstrap';
import App from 'App';
import { setToken } from 'utils';

export default function Login(): JSX.Element {
    const [name, setName] = React.useState('');
    const [lastname, setLastname] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [redirect, setRedirect] = React.useState(false);

    const handleSignin = () => {
        if ((name.trim() !== '') && (lastname.trim() !== '') && (password.toLowerCase() === 'fleming2018' || password.toLowerCase() === 'fleming 2018')){
            setToken(name, lastname);
            setRedirect(true);
        }else{
            alert('Por favor llene los datos correctamente');
        }
    };

    if (redirect){
        return <App />;
    }

    return (
        <div className="main-container-login">
            <div className="main-frame">
                <h1>Congr. Fleming</h1>
                <Form className="login-form" onSubmit={handleSignin}>
                    <FormGroup row className="pb-2">
                        <Label for="name" sm={2}>Nombre</Label>
                        <Col sm={10}>
                            <Input className="login-form-input" type="text" name="name" value={name} onChange={(event: React.SyntheticEvent) => setName((event.target as HTMLFormElement).value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row className="pb-2">
                        <Label for="lastname" sm={2}>Apellido</Label>
                        <Col sm={10}>
                            <Input className="login-form-input" type="text" name="lastname" value={lastname} onChange={(event: React.SyntheticEvent) => setLastname((event.target as HTMLFormElement).value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row className="pb-2">
                        <Label for="password" sm={2}>Contraseña</Label>
                        <Col sm={10}>
                            <Input type="password" name="password" value={password} onChange={(event: React.SyntheticEvent) => setPassword((event.target as HTMLFormElement).value)} />
                        </Col>
                    </FormGroup>
                    <div className="text-center">
                        <button className="login-button">ENTRAR</button>
                    </div>
                </Form>
            </div>
        </div>
    );
}
