import { service } from 'config';
import Layout from 'components/general/Layout';
import useData from 'hooks/useData';
import { groupBy, formatDate, isCurrent } from 'utils';
import NoData from 'components/general/NoData';

export default function PreachingPublic (): JSX.Element {
    const { data: allData } = useData(service, 'Carritos');
    const rawData = allData as any;

    const rows: any[] = rawData?.map((item: any) => ({
        fecha: item.c[0]?.v ||'',
        hora: item.c[1]?.v ||'',
        carrito1A: item.c[2]?.v ||'',
        carrito1B: item.c[3]?.v ||'',
        carrito2A: item.c[4]?.v ||'',
        carrito2B: item.c[5]?.v ||'',
    })) || [];

    const grouped = groupBy(rows, 'fecha');

    const data = [];

    for (const ii in grouped) {
        data.push({
            fecha: ii,
            servicio: grouped[ii], // removeDuplicates(grouped[ii], 'grupos'),
        });
    }

    const currentData = data.filter((item: any) => isCurrent(item.fecha));

    return (
        <Layout title="Carritos">
            {!currentData.length && (
                <NoData />
            )}
            {currentData.map((item: any) => (
                <div key={item.fecha}>
                    <div className="block-container" key={item.fecha}>
                        <div className="service-container">
                            <div className="date">{formatDate(item.fecha)}</div>
                            <ul>
                                {item.servicio.map((ss: any, index: number) => (
                                    <li key={index} className="mx-2">
                                        <span className="hora">{ss.hora}</span>

                                        {ss.carrito1A && ss.carrito1B && (
                                            <>
                                                <div className="titulo">CARRITO 1</div>
                                                <div className="carrito1A" style={{marginLeft: '10px'}}>{`${ss.carrito1A} / ${ss.carrito1B}`}</div>
                                            </>
                                        )}
                                        {ss.carrito2A && ss.carrito2B && (
                                            <>
                                                <div className="titulo">CARRITO 2</div>
                                                <div className="carrito1A" style={{marginLeft: '10px'}}>{`${ss.carrito2A} / ${ss.carrito2B}`}</div>
                                            </>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            ))}
        </Layout>
    );
}
