import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from 'components/general/PrivateRoute';
import Header from 'components/general/Header';
import Footer from 'components/general/Footer';
import Page404 from 'components/general/Page404';
import Preaching from 'components/pages/Preaching';
import PreachingZoom from 'components/pages/PreachingZoom';
import Speaches from 'components/pages/Speaches';
import SpeachesOut from 'components/pages/SpeachesOut';
import Cleaning from 'components/pages/Cleaning';
import PreachingPublic from 'components/pages/PreachingPublic';
import PresidentReader from 'components/pages/ChairmanReader';
import NoVisit from 'components/pages/NoVisit';
import Various from 'components/pages/Various';
import Groups from 'components/pages/Groups';
import Login from 'components/general/Login';
import { QueryClient, QueryClientProvider } from 'react-query';
import { getToken } from 'utils';

import ReactGA from 'react-ga';

const queryClient = new QueryClient();

function initializeReactGA() {
    ReactGA.initialize('UA-135514239-1', {
        gaOptions: {
            name: localStorage.getItem('name') + ' ' + localStorage.getItem('lastname')
        }
    });
    ReactGA.pageview('/');
}


export default function App(): JSX.Element {
    initializeReactGA();

    if (!getToken()){
        return <Login />;
    }
    return (
        <QueryClientProvider client={queryClient}>
            <Header />
            <Router>
                <Switch>
                    <Route exact path="/login"><Login /></Route>
                    <PrivateRoute exact path="/conferencias"><Speaches /></PrivateRoute>
                    <PrivateRoute exact path="/conferencias-salientes"><SpeachesOut /></PrivateRoute>
                    <PrivateRoute exact path="/aseo"><Cleaning /></PrivateRoute>
                    <PrivateRoute exact path="/presidentes-lectores"><PresidentReader /></PrivateRoute>
                    <PrivateRoute exact path="/no-pasar"><NoVisit /></PrivateRoute>
                    <PrivateRoute exact path="/varios"><Various /></PrivateRoute>
                    <PrivateRoute exact path="/carritos"><PreachingPublic /></PrivateRoute>
                    <PrivateRoute exact path="/grupos"><Groups /></PrivateRoute>
                    <PrivateRoute exact path="/zoom"><PreachingZoom /></PrivateRoute>
                    <PrivateRoute exact path="/"><Preaching /></PrivateRoute>
                    <Route><Page404 /></Route>
                </Switch>
            </Router>
            <Footer/>
        </QueryClientProvider>
    );
}




