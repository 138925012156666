import * as React from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { congr_name, menu } from 'config';

export default function Header(): JSX.Element {
    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <Navbar dark expand="md" sticky="top" className="header px-4 d-flex justify-content-between">
            <NavbarBrand href="/">Congr. {congr_name}</NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                    {menu.map((item, index) => (
                        <UncontrolledDropdown nav inNavbar key={index}>
                            <DropdownToggle nav caret>
                                {item.title}
                            </DropdownToggle>
                            <DropdownMenu right>
                                {item.submenu.map((subitem, index) => (
                                    subitem.title === '' ?
                                        <DropdownItem key={index} divider />
                                        :
                                        <DropdownItem key={index} href={subitem.url}>
                                            {subitem.title}
                                        </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    ))}
                </Nav>
            </Collapse>

        </Navbar>
    );
}

