import { speaches } from 'config';
import Layout from 'components/general/Layout';
import useData from 'hooks/useData';
import { formatDate, isCurrent } from 'utils';
import NoData from 'components/general/NoData';

export default function Speaches(): JSX.Element {
    const { data: allData } = useData(speaches, 'Publico');
    const rawData = allData as any;

    const data = rawData?.map((item: any) => ({
        fecha: item.c[0]?.f ||'',
        nro: item.c[1]?.v ||'',
        tema: item.c[2]?.v ||'',
        discursante: item.c[3]?.v ||'',
        congregacion: item.c[4]?.v ||'',
    })) || [];

    const currentData = data.filter((item: any) => isCurrent(item.fecha));

    return (
        <Layout title="Conferencias">
            {!currentData.length && (
                <NoData />
            )}
            {currentData.map((item: any, index: number) => (
                <div className="block-container" key={index}>
                    <div className="date">{formatDate(item.fecha)}</div>
                    <div className="speach-theme">{item.tema}</div>
                    <div className="speacher">{item.discursante ? ('- '+item.discursante+' -') : ''}</div>
                    {item.parte1 && <div className="speach-theme"><a href={item.parte1} target="_blank" rel="noopener noreferrer">Ver Parte 1</a></div>}
                    {item.parte2 && <div className="speach-theme"><a href={item.parte2} target="_blank" rel="noopener noreferrer">Ver Parte 2</a></div>}
                    {item.parte3 && <div className="speach-theme"><a href={item.parte3} target="_blank" rel="noopener noreferrer">Ver Parte 3</a></div>}
                    {item.parte4 && <div className="speach-theme"><a href={item.parte4} target="_blank" rel="noopener noreferrer">Ver Parte 4</a></div>}
                    <div className="congregation">{item.congregacion ? ('Congr. '+item.congregacion) : ''}</div>
                </div>
            ))}
        </Layout>
    );
}



